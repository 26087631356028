import React, { useRef, useState } from "react";
import {
  FaCalculator,
  FaFileAlt,
  FaLock,
  FaMapMarkerAlt,
  FaPencilAlt,
  FaPhoneAlt,
  FaRegEnvelope,
  FaUserAlt,
} from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
const SignUpForm = () => {
  const form = useRef();
  const [selectType, setSelectType] = useState("individual");
  const sendEmail = (e) => {
    e.preventDefault();
    // Please See Documentation for more information
    emailjs
      .sendForm(
        "service_yipk4xg", //YOUR_SERVICE_ID
        "template_71bgc2q", //YOUR_TEMPLATE_ID
        form.current,
        "cwf8kROl5o3__96Ti" //YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast.success("Massage Sent Successfully!");
            form.current[0].value = "";
            form.current[1].value = "";
            form.current[2].value = "";
            form.current[3].value = "";
          }
        },
        (error) => {
          if (error.text !== "OK") {
            toast.success("Massage Not Sent!");
          }
        }
      );
  };
  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      {/* contact area start */}
      <div className="container">
        <div className="contact-area mg-top-120 mb-120">
          <div className="row g-0 justify-content-center">
            <div className="col-lg-7">
              <form
                className="contact-form text-center"
                ref={form}
                onSubmit={sendEmail}
              >
                <h3>Sign Up</h3>
                <div className="row">
                  <div className="col-md-12" style={{ paddingBottom: 10 }}>
                    <input
                      type="radio"
                      name="checker_type"
                      checked={selectType == "individual" ? true : false}
                      onChange={(e) => setSelectType(e.target.value)}
                      value={"individual"}
                    />
                    &nbsp;<label>Individual</label> &nbsp; &nbsp;
                    <input
                      type="radio"
                      name="checker_type"
                      checked={selectType == "ecommerence" ? true : false}
                      onChange={(e) => setSelectType(e.target.value)}
                      value={"ecommerence"}
                    />
                    &nbsp; <label>Ecommerce</label>
                  </div>
                  {selectType == "individual" ? (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="single-input-inner">
                          <label>
                            <FaUserAlt />
                          </label>
                          <input
                            type="text"
                            placeholder="First name"
                            name="first_name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-input-inner">
                          <label>
                            <FaUserAlt />
                          </label>
                          <input
                            type="text"
                            placeholder="Last name"
                            name="last_name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-input-inner">
                          <label>
                            <FaRegEnvelope />
                          </label>
                          <input
                            type="text"
                            placeholder="Your email"
                            name="user_email"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-input-inner">
                          <label>
                            <FaCalculator />
                          </label>
                          <input type="text" name="phone_number" placeholder=" Phone number" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-input-inner">
                          <label>
                            <FaLock />
                          </label>
                          <input type="text" name="password" placeholder="Password" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-input-inner">
                          <label>
                            <FaLock />
                          </label>
                          <input type="text" name="confirm_password" placeholder="Confirm Password" />
                        </div>
                      </div>
                    
                   
                      <div className="col-12">
                        <button className="btn btn-base" type="submit">
                          {" "}
                          Sign Up 
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                        <div className="col-md-6">
                        <div className="single-input-inner">
                          <label>
                            <FaUserAlt />
                          </label>
                          <input
                            type="text"
                            placeholder="First name"
                            name="first_name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-input-inner">
                          <label>
                            <FaUserAlt />
                          </label>
                          <input
                            type="text"
                            placeholder="Last name"
                            name="last_name"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-input-inner">
                          <label>
                            <FaRegEnvelope />
                          </label>
                          <input
                            type="text"
                            placeholder="Your email"
                            name="user_email"
                          />
                        </div>
                      </div>
                      
                      <div className="col-md-12">
                        <div className="single-input-inner">
                          <label>
                            <FaLock />
                          </label>
                          <input type="text" name="password" placeholder="Password" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-input-inner">
                          <label>
                            <FaLock />
                          </label>
                          <input type="text" name="confirm_password" placeholder="Confirm Password" />
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-base" type="submit">
                          {" "}
                         Sign Up 
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12" style={{paddingTop:10}}>
                    <a href="/login">Already have a account? Sign in</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* contact area end */}

    </>
  );
};

export default SignUpForm;
